import { type MetaFunction } from '@remix-run/node'
import { Link } from '@remix-run/react'
import commandPallette from '#app/assets/command-pallet.png'
import { useOptionalUser } from '#app/utils/user.js'

export const meta: MetaFunction = () => [{ title: 'ScholBooks' }]


export default function Index() {

	const user = useOptionalUser()

	return (
		<main className="font-poppins grid h-full place-items-center">
			<div className="grid place-items-center gap-16 px-4 py-16">
				<div className="flex max-w-md flex-col justify-center text-center xl:items-start xl:text-left">
					<h1
						data-heading
						className="animate-slide-top xl:animate-slide-left mt-8 text-4xl font-medium text-foreground [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-5xl xl:mt-4 xl:text-6xl xl:[animation-delay:0.8s] xl:[animation-fill-mode:backwards]"
					>
						<a href="https://www.scholbooks.app/">
							<span className="font-light">Schol</span>
							<span className="font-bold">Books</span>
						</a>
					</h1>
					<p
						data-paragraph
						className="animate-slide-top xl:animate-slide-left mt-6 text-xl/7 text-muted-foreground [animation-delay:0.8s] [animation-fill-mode:backwards] xl:mt-8 xl:text-xl/6 xl:leading-10 xl:[animation-delay:1s] xl:[animation-fill-mode:backwards]"
					>
						The best fees management solution for Indian Schools. With automated
						accounting.
					</p>

					{user? <Link
						to={'/app'}
						className="rounded border bg-primary p-2 px-4 text-primary-foreground"
					>
						Go to app
					</Link>: null}
				</div>
				<img
					src={commandPallette}
					alt="command pallette"
					className="max-w-[700px]"
				/>
			</div>
		</main>
	)
}
